.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.divFlex1 {
  flex: 1;
}
.router-content {
  display: flex;
  -webkit-box-flex: 1;
  flex-grow: 1;
}

.no-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
