.ChaletScreenContainer {
  padding-top: 20px;
  padding-bottom: 20px;
  flex: 1;
  justify-content: center;
  align-items: center;
  margin: 0;
  height: calc(100% - 122px);
}

.DateControllerWithTitleContainer {
  border-width: 0px 0px 2px 0px;
  border-style: solid;
  border-color: map-get($theme-colors, onBackgroundColor);
  margin-bottom: 10px;
  font-size: 0.8rem;
  p {
    color: map-get($theme-colors, onBackgroundColor);
  }

  .left {
    text-align: left;
    color: map-get($theme-colors, onBackgroundColor);
  }

  .right {
    text-align: right;
    color: map-get($theme-colors, onBackgroundColor);
  }
  .title {
    color: map-get($theme-colors, onBackgroundColor);
    font-weight: bold;
    text-transform: uppercase;
  }
}
.DateBodyContainer {
  min-width: 100%;
  border-style: solid;
  border-color: map-get($theme-colors, darkBorderColor);
  color: map-get($theme-colors, onFadedBackgroundColor);
  background-color: map-get($theme-colors, FadedBackgroundColor);
  .day {
    border-width: 2px 0px 0px 2px;
    border-style: solid;
    border-color: map-get($theme-colors, darkBorderColor);
    padding-bottom: 10px;
  }
  .disabled {
    color: map-get($theme-colors, oldDateColor);
  }
  .today {
    background-color: map-get($theme-colors, FadedBackgroundColor);
  }

  th {
    text-transform: uppercase;
    font-weight: 400;
    padding-top: 10px;
  }
  .periodContainer {
    min-width: 100%;
    background-color: blueviolet;
  }

  .periodAvailabilityContainer {
    padding-right: 5px;
    padding-left: 5px;
    border-width: 0px 2px 0px 2px;
  }
  .periodAvailable {
    background-color: map-get($theme-colors, secondary);
    padding: 5px;
    // padding-bottom: 5px;
    text-transform: uppercase;
    color: map-get($theme-colors, onBackgroundColor);
    font-size: 0.8rem;
  }
  .periodUnavailable {
    background-color: map-get($theme-colors, primary);
    padding-top: 5px;
    padding-bottom: 5px;
    text-transform: uppercase;
    color: map-get($theme-colors, onBackgroundColor);
    font-size: 0.8rem;
  }
}
.ImagesContainer {
  margin-top: 10px;
  .title {
    color: map-get($theme-colors, onSecondaryColor);
    font-weight: bold;
  }
  .picturesContainer {
    margin-top: 10px;
  }
  .pictureButton {
    color: map-get($theme-colors, onSecondaryColor);
    padding-top: 10px;
    padding-bottom: 10px;
    font-weight: bold;
  }
}

.CustomerCommentContainer {
  margin-top: 10px;
  .title {
    color: map-get($theme-colors, onBackgroundColor);
    font-size: 2rem;
  }

  .card {
    background: rgba(255, 255, 255, 0.6);
    color: #222222;
    padding: 20px;
    padding-left: 50px;
    box-sizing: border-box;
    box-shadow: 0 2px 4px rgba(34, 34, 34, 0.12);
    position: relative;
    overflow: hidden;
    min-height: 120px;
    margin-bottom: 10px;
  }

  .card:before {
    font-family: Georgia, serif;
    content: "“";
    position: absolute;
    top: -0.5rem;
    left: 0.8rem;
    font-size: 5em;
    color: rgba(0, 0, 0, 0.2);
    font-weight: normal;
  }

  .card:after {
    font-family: Georgia, serif;
    content: "”";
    position: absolute;
    bottom: -3.5rem;
    line-height: 0rem;
    right: 0px;
    font-size: 15rem;
    color: rgba(0, 0, 0, 0.2);
    font-weight: normal;
  }
  .card p {
    font-size: 1.3em;
    line-height: 1.3rem;
    margin: 10px;
    max-width: 80%;
  }

  .card cite {
    font-size: 16px;
    margin-top: 10px;
    display: flex;
    font-weight: 200;
    opacity: 0.8;
  }
}

.carousel .thumb img {
  width: 100% !important;
  height: 100% !important;
}

.carousel-item img {
  max-height: 25rem; /* change this to whatever you want */
  width: auto;
  object-fit: cover;
}
.image-gallery-image {
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 8px;
  padding: 5px;
  width: 10px;
}

.MapContainer {
  height: 400px;
}
