.SurveyScreenContainer {
  padding: 20px;
  flex: 1;
  background-size: cover;
  transition: all 0.6s;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;

  .sv-action-bar {
    align-items: center !important;
    justify-content: center !important;
  }

  .surveyLayout {
    direction: rtl;
    background-color: map-get($theme-colors, FadedBackgroundColor) !important;
    border-radius: 1.5rem;
    // position: absolute;
    // top: 0;
    // right: 0;
    // left: 0;
  }
  .sv_main input[type="button"],
  .sv_main button {
    background-color: map-get($theme-colors, secondary);
    padding-top: 10px;
    padding-bottom: 10px;
    width: 25%;
  }

  .sv_main .sv_container .sv_body .sv_nav .sv_start_btn,
  .sv_main .sv_container .sv_body .sv_nav .sv_next_btn,
  .sv_main .sv_container .sv_body .sv_nav .sv_complete_btn {
    float: none;
  }

  .sv_main .sv_body {
    background-color: transparent;
    border-top: 0px solid map-get($theme-colors, secondary);
    // color: map-get($theme-colors, onBackgroundColor) !important;
    color: black !important;
  }
  .sv_main {
    background-color: transparent;
  }
  .sv_main .sv_custom_header {
    background-color: transparent;
  }

  .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_q table.sv_q_matrix td::after,
  .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_qstn table.sv_q_matrix td::after {
    margin: 10px;
  }
  .sv_main .sv_container .sv_body .sv_p_root table.sv_q_matrix td:first-child {
    text-align: unset;
    font-weight: bold;
  }
  .sv_row {
    background-color: transparent !important;
  }

  .sv_q_title,
  .sv-string-viewer,
  .sv_q_m_cell {
    font-size: 1.5em;
  }
  textarea {
    background-color: map-get($theme-colors, FadedBackgroundColor) !important;
    resize: none !important;
    font-size: 1.2em !important;
    border-radius: 18px;
  }
  textarea:focus {
    border: 10px;
    border-top-color: map-get($theme-colors, primary) !important;
    border-bottom-color: map-get($theme-colors, primary) !important;
    border-right-color: map-get($theme-colors, primary) !important;
    border-left-color: map-get($theme-colors, primary) !important;

    border-block-end-width: 2px !important;
    border-right-width: 2px !important;
    border-top-width: 2px !important;
    border-left-width: 2px !important;

    padding-right: 14px;
  }
  .sv_q_num {
    display: none;
  }

  .sv_row,
  .sv_q_matrix {
    margin-top: 20px;
  }
  .sv_q_matrix {
    margin-bottom: 10px;
  }

  .final-page {
    height: 50vh;
    width: 50vw;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;

    .h1 {
      text-align: center;
      text-justify: center;
      font-weight: bold;
    }
  }
}

.SurveyScreenContainer::before {
  background-image: url("../../Images/ChaletImages/ChaletFatimaBox.jpg");
  background-size: cover;
  content: "";
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: -1;
  display: block;
  height: 100vh;
  width: 100vw;
  z-index: -1;
  -webkit-filter: blur(5px);
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);
  filter: blur(5px);
  margin-top: -100px;
  transform: scale(1.3);
}
