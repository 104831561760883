.TitleBarContainer {
  background-color: map-get($theme-colors, primary);
  padding: 10px;
  text-align: center;

  h1 {
    font-weight: bold;
    font-size: 1.5rem;
    color: map-get($theme-colors, onPrimaryColor);
  }
}
