.LoadingContainer {
  text-align: center;
  background-color: map-get($theme-colors, primary);
  color: map-get($theme-colors, onPrimaryColor);
  font-size: x-large;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // min-height: 100vh;
  // min-width: 100vh;
  margin: 0;
  text-align: "center";
  text-justify: "center";
}

.font {
  pointer-events: none;
}

.loader {
  border: 8px solid map-get($theme-colors, FadedBackgroundColor);
  border-top: 8px solid map-get($theme-colors, secondary);
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
