.ChaletBoxContainer {
  text-align: center;
  color: map-get($theme-colors, onPrimaryColor);
  font-size: x-large;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  margin-bottom: 35px;
  aspect-ratio: 1/1;
  max-height: 440px;
  min-height: 440px;
  max-width: 440px;
  min-width: 440px;
  
}

.CardImage {
  pointer-events: none;
  max-height: 400px;
  min-height: 400px;
  max-width: 400px;
  min-width: 400px;
  object-fit: cover;
}
