//@import "variables";
$theme-colors: (
  "primary": #0f3e68,
  "onPrimaryColor": #ffffff,
  "onSecondaryColor": #ffffff,
  "onBackgroundColor": #ffffff,
  "FadedBackgroundColor": rgba(255, 255, 255, 0.4),
  "oldDateColor": rgba(0, 0, 0, 0.4),
  "onFadedBackgroundColor": #000000,
  "darkBorderColor": #696969,
  "secondary": #d97529,
  "danger": #ff4136,
);
$--bs-gutter-x: 0px !default;
$font-family-sans-serif: Montserrat;
$font-family-serif: Montserrat;
$font-family-monospace: Montserrat;
$font-family-base: Montserrat;
// libraries
@import "../../node_modules/bootstrap/scss/bootstrap.scss";
@import "../../node_modules/react-image-gallery/styles/scss/image-gallery.scss";

//Screens
@import "./Screens/App.scss";
@import "./Screens/HomeScreenStyle.scss";
@import "./Screens/ChaletScreenStyle.scss";
@import "./Screens/PictureScreenContainer.scss";
@import "./Screens/SurveyScreenStyle.scss";
@import "./Screens/PageNotFoundStyle.scss";

//Components
@import "./Components/TitleBarStyle.scss";
@import "./Components/FooterStyle.scss";
@import "./Components/ChaletBox.scss";
@import "./Components/Loading.scss";
