.background404 {
  background-image: url("../../Images/SkyBackgroundImage.jpg");
  background-size: cover;
  height: 100vh;
  transition: all 0.6s;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  h1 {
    // font-size: 50px;
    padding-right: 12px;
    color: black;
    font-weight: bold;
  }

  h2 {
    color: rgba(0, 0, 0, 0.7);
    font-weight: bold;
  }
}
